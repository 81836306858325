<template>
  <div
    id="message"
    class="el-message el-message--warning"
    style="top:16px;z-index:2087;display: none;align-items: normal;"
  >
    <el-icon style="color: #e6a23c;margin-right: 4px;">
      <warning-filled />
    </el-icon>

    <p class="el-message__content" @click="toCarryover" style="cursor: pointer;">
      温馨提示:有{{notOnList}}家企业未在申报列表：{{notOnCurrentPeriod}}家未结账到本账期（<a>点击查看</a>）
      <!-- <p style="margin-top: 3px;margin-left: 238px;" v-if="noTax">{{ noTax }}家没有税表</p> -->
    </p>
    <el-icon style="margin-left: 10px;color: #999;cursor: pointer;" @click="close">
      <close />
    </el-icon>
  </div>
</template>

<script>
import { getUnclosed } from "@/api/carryover";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      noTax: 0,
      notOnList:0,
      notOnCurrentPeriod:0
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let box = document.getElementById("message")
      getUnclosed({}).then((res) => {
        if (res.data.msg == "success" && res.data.data.notOnList) {
          box.style.display = 'flex';
          this.noTax = res.data.data.noTax;
          this.notOnList = res.data.data.notOnList;
          this.notOnCurrentPeriod = res.data.data.notOnCurrentPeriod;
          setTimeout(()=>{
            box.style.display = 'none';
          },5000)
        }
      });
    },
    close(){
      document.getElementById("message").style.display = 'none';
    },
    toCarryover(){
      this.$store.dispatch("user/setModel", "财务核算");
      this.$store
        .dispatch("user/GetUserInfo")
        .then((res) => {
          let indexPath = res.indexPath;
          this.$store
            .dispatch("router/SetAsyncRouter", res.roles)
            .then((res) => {
              this.$router.push({
                path: '/financial-acco/batch-settle',
                name: "batch-settle",
                query: { from: 'hint' }
              });
              this.$store.dispatch("tagsView/delAllViews");

            });
        })
        .catch((err) => {});
    }
  },
};
</script>

<style scoped lang="scss">

.el-message__content a{
  text-decoration: underline;
  color: #409eff;
}
</style>
